import TopHeader from "./components/top_header"
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ApiService } from "../Service/apiservices";
import DataContext from "../Element/context";
import Menu from "./components/menu";
import constant from "../Service/constant";
import Skeleton from "react-loading-skeleton";
import MobileMenu from "../Modal/mobile_menu_modal";
import { useNavigate } from "react-router-dom";
import CartModal from "../Modal/cart_modal";
import SearchModal from "../Modal/search_modal";
import VariationModal from "../Modal/variation_modal";
const Header = ({ innerHeader }) => {
  const contextValues = useContext(DataContext)
  const navigate = useNavigate()
  const MobileMenuModal = () => {
    contextValues.setToggleMenuModal(!contextValues.toggleMenuModal)
  }
  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }

  const searchModal = () => {
    contextValues.setToggleSearchModal(!contextValues.toggleSearchModal)
  }
  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';
    const isMobile = window.innerWidth <= 768;
    if (isBrowser) {
      const handleScroll = () => {
        const headerBottomElement = document.getElementById("header_bottom");
        const headerElement = document.getElementById("header");
        if (headerBottomElement) {
          if (window.scrollY > 200) {
            headerBottomElement.classList.add("fixed-head");

            const fixedHeaderElement = document.querySelector(".fixed-header");
            if (fixedHeaderElement) {
              const navbarHeight = fixedHeaderElement.offsetHeight;
              document.body.style.paddingTop = navbarHeight + "px";
            }
          } else {
            headerBottomElement.classList.remove("fixed-head");
            document.body.style.paddingTop = "0";
          }
        }
        if (headerElement) {
          if (isMobile && window.scrollY > 50) {
            headerElement.classList.add("fixed-head");

            const fixedHeaderElement = document.querySelector(".fixed-header");
            if (fixedHeaderElement) {
              const navbarHeight = fixedHeaderElement.offsetHeight;
              document.body.style.paddingTop = navbarHeight + "px";
            }
          } else {
            headerElement.classList.remove("fixed-head");
            document.body.style.paddingTop = "0";
          }
        }
      };
      const handleScrollLogo = () => {
        const logoElement = document.getElementById("logo_fixed");
        if (logoElement) {
          if (window.scrollY > 200) {
            logoElement.classList.add("logo-transition-stickly");

            const logoTransitionElement = document.querySelector(".logo-transition");
            if (logoTransitionElement) {
              const navbarHeight = logoTransitionElement.offsetHeight;
              document.body.style.paddingTop = navbarHeight + "px";
            }
          } else {
            logoElement.classList.remove("logo-transition-stickly");
            document.body.style.paddingTop = "0";
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("scroll", handleScrollLogo);
      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("scroll", handleScrollLogo);
      };
    }
  }, []);

  const [menuData, setmenuData] = useState([])
  const [isLoading, setisLoading] = useState(true)
  const [imageUrl, setImageUrl] = useState("");

  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      getmenuList()
    }
    didMountRef.current = false;
  }, []);

  const getmenuList = () => {
    ApiService.fetchData('/menue-list').then(result => {
      if (result.status == 'success') {
        setmenuData(result.data)
        setImageUrl(result.imageUrl);
        setTimeout(() => {
          setisLoading(false)
        }, 500)
      }
      else {
        setTimeout(() => {
          setisLoading(false)
        }, 500)
      }

    }).catch((error) => {
      setTimeout(() => {
        setisLoading(false)
      }, 500)
    })
  }

  return (<>
    <BrowserView>
      <header className={`header ${innerHeader !== '' ? innerHeader : ''}`}>
        <div className="header-top" ><TopHeader></TopHeader></div>
        <div className="header-middle">
          {isLoading ? <>
            <div className="container-fluid justify-content-center">
              <a href="javascript:void(0)" className="logo"><Skeleton width={300} height={61}></Skeleton></a>
              <a href="javascript:void(0)" className="hoverlogo"><Skeleton width={600} height={61}></Skeleton></a>
            </div>
          </> :
            <div className="container-fluid justify-content-center">
              <div className={`logo-transition`} id='logo_fixed'>
                <a href="/" className="logo"><img  src={contextValues.settingData.white_logo ? contextValues.settingImageBaseUrl+contextValues.settingData.white_logo:'/img/logow.png'} width={300} height={60}></img></a>
                <a href="/" className="hoverlogo"><img src={contextValues.settingData.logo ?contextValues.settingImageBaseUrl+ contextValues.settingData.logo:'/img/logo.png'} width={300} height={60}></img></a>
              </div>
            </div>
          }
        </div>
        <div className={`fixed-header header-bottom`} id="header_bottom">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <ul className="navbar-nav mx-auto align-items-center">
                {isLoading ? <li className="nav-item navLogo"><a href="javascript:void(0)" className="nav-link"><Skeleton width={200} height={21}></Skeleton></a></li> : <li className="nav-item navLogo"><a href="/" className="nav-link"><img src={contextValues.settingData.logo ?contextValues.settingImageBaseUrl+ contextValues.settingData.logo:'/img/logo.png'} width={200} height={21}></img></a></li>}
                {/* menu start */}
                <Menu menuData={menuData} isLoading={isLoading} imageUrl={imageUrl}></Menu>
                {/* Menu End */}
                {!isLoading && <>
                  <li className="nav-item">
                    <a className="nav-link searchIcon" href="javascript:void(0)" onClick={() => { searchModal() }}><i className="ri-search-line ri-lg"></i></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link cartToggle" href="javascript:void(0)" onClick={() => { cartModal() }}><i className="ri-shopping-cart-line ri-lg"></i>{Number(contextValues.cartCount) > 0 &&
                      <span className="count">{contextValues.cartCount}</span>}</a>
                  </li>
                </>}
              </ul>
            </div>
          </nav>
        </div>
      </header>
      </BrowserView>
    <MobileView>
      <header className={`${innerHeader !== '' ? 'innerHeader' : 'fixed-header'} header mheader`} id="header">
        <div className="container-fluid">
          <div className="headerLeft">
            <ul className="leftHeaderlist">
              <li>
                <a href="javascript:void(0)" onClick={() => { MobileMenuModal() }}><i className="ri-menu-line"></i></a>
              </li>
            </ul>
          </div>
          <div className="headerMiddle">
            <a href="/" className="mlogo"><img src={contextValues.settingData.white_logo ? contextValues.settingImageBaseUrl+contextValues.settingData.white_logo:'/img/logow.png'} width={250} height={31}></img></a>
            <a href="/" className="mlogofix"><img src={contextValues.settingData.logo ?contextValues.settingImageBaseUrl+ contextValues.settingData.logo:'/img/logo.png'} width={250} height={31}></img></a>
          </div>
          <div className="headerRight justify-content-end">
            <ul className="rightHeaderlist">
              <li>
                <a href="javascript:void(0)" onClick={() => { searchModal() }}>
                  <i className="ri-search-line ri-lg"></i>
                </a>
              </li>
              <li>
                <a className="cartToggle" href="javascript:void(0)" onClick={() => { cartModal() }}>
                  <i className="ri-shopping-cart-line ri-lg"></i>{Number(contextValues.cartCount) > 0 &&
                    <span className="count">{contextValues.cartCount}</span>}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </MobileView>
    {contextValues.toggleMenuModal && <MobileMenu></MobileMenu>}
    {contextValues.toggleSearchModal && <SearchModal></SearchModal>}
    {contextValues.toggleCartModal && <CartModal></CartModal>}
    {contextValues.toggleVariationModal && <VariationModal></VariationModal>}
  </>)
}

export default Header