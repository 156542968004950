import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Container/Home';
import Pages from './Container/Pages';
import Cart from './Container/Cart/index.js';
import Checkout from './Container/Checkout';
import Blogs from './Container/Pages/About/blogs';
import Glossary from './Container/Pages/About/glossary';
import MaterialOfPadmakshya from './Container/Pages/About/material_of_padmakshya';
import Stores from './Container/Pages/About/stores';
import Testimonial from './Container/Pages/About/testimonial';
import PadmakshyaClub from './Container/Pages/padmakshya_club';
import ProductDetail from './Container/ProductDetail';
import Wishlist from './Container/Account/wishlist';
import BlogDetail from './Container/Pages/About/blog_detail'
import ProductList from './Container/ProductList/index.js';
import PageNotFound from './Container/Pages/page_not_found.js';
import WriteReview from './Container/WriteReview/index.js';
import MyAccount from './Container/Account/my_account.js';
import OrderDetail from './Container/Account/order_detail.js';
import MyOrders from './Container/Account/my_orders.js';
import MyAddress from './Container/Account/my_address.js';
import HelpSupport from './Container/Account/help-support.js';
import MyProfile from './Container/Account/my_profile.js';
import Login from './Container/Login/index.js';
import Register from './Container/Register/index.js'
import FAQ from './Container/Pages/Customer_Service/faq.js';
import GiftCard from './Container/Pages/Customer_Service/gift_card.js';
import VerifyEmail from './Container/EmailVerification/index.js';
import ResetPassword from './Container/ResetPassword/index.js';
import JewelleryCare from './Container/Pages/Membership/jewellery-care.js';
import OrderStatus from './Container/Pages/Customer_Service/order_status.js';
import SizeGuide from './Container/Pages/Customer_Service/size_guide.js';
import ReturnExchnage from './Container/Pages/Customer_Service/return_exchange.js';
import Shipping from './Container/Pages/Customer_Service/shipping.js';
import SearchResult from './Container/SearchResult/index.js';
import SearchModal from './Component/Modal/search_modal.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Address from './Container/Checkout/address.js';
import Thankyou from './Container/Checkout/thankyou.js';
import Unsubscribe from './Container/Pages/Unsubscribe/index.js';
import GiftCardThankyou from './Container/Pages/giftcardthankyou.js';


function App() {
  return (
    <>

<ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
      <Router>
        <Routes>
          <Route path="/" activeClassName="active" element={<Home />} />

          {/* cart routes start */}
          <Route path="/cart" activeClassName="active" element={<Cart />} />
          <Route path="/address" activeClassName="active" element={<Address />} />
          <Route path="/checkout" activeClassName="active" element={<Checkout />} />
          <Route path="/thankyou/:id"  activeClassName="active" element={<Thankyou/>} />
          <Route path="/giftcardthankyou/:id"  activeClassName="active" element={<GiftCardThankyou/>} />
          {/* cart routes end */}

          {/* Account Routes start */}
          <Route path="/order-detail/:id" activeClassName="active" element={<OrderDetail />} />
          <Route path="/my-account" activeClassName="active" element={<MyAccount />} />
          <Route path="/account/wishlist" activeClassName="active" element={<Wishlist />} />
          <Route path="/account/my-order" activeClassName="active" element={<MyOrders />} />
          <Route path="/account/my-address" activeClassName="active" element={<MyAddress />} />
          <Route path="/account/profile" activeClassName="active" element={<MyProfile />} />
          <Route path="/help-support" activeClassName="active" element={<HelpSupport />} />
          {/* Account Routes start */}

          {/* Login routes start */}
          <Route path="/login" activeClassName="active" element={<Login />} />
          <Route path="/register" activeClassName="active" element={<Register />} />
          <Route path="/verify-email/:token" activeClassName="active" element={<VerifyEmail />} />
          <Route path="/unsubscribe" activeClassName="active" element={<Unsubscribe />} />
          <Route path="/change-password/:token" activeClassName="active" element={<ResetPassword />} />
          {/* Login routes end */}

          {/* product routes start */}
          <Route path="/list/:type/:slug?" activeClassName="active" element={<ProductList />} />
          <Route path="/list/all" activeClassName="active" element={<ProductList />} />
          <Route path="/product/:slug" activeClassName="active" element={<ProductDetail />} />
          {/* product routes start */}

          <Route path="/blog" activeClassName="active" element={<Blogs />} />
          <Route path="/blog/:slug" activeClassName="active" element={<BlogDetail />} />
          <Route path="/review" activeClassName="active" element={<WriteReview />} />

          {/* about routes start */}
          <Route path="/glossary" activeClassName="active" element={<Glossary />} />
          <Route path="/materials-of-padmakshya" activeClassName="active" element={<MaterialOfPadmakshya />} />
          <Route path="/stores" activeClassName="active" element={<Stores />} />
          <Route path="/jewellery-care" activeClassName="active" element={<JewelleryCare />} />
          <Route path="/testimonials" activeClassName="active" element={<Testimonial />} />
          {/* about routes end */}
       
          {/* customer service routes start */}
          <Route path="/faq" activeClassName="active" element={<FAQ />} />
          {/* <Route path="/gift-card" activeClassName="active" element={<GiftCard />} /> */}
          <Route path="/order-status" activeClassName="active" element={<OrderStatus />} />
          <Route path="/size-guide" activeClassName="active" element={<SizeGuide />} />
          <Route path="/return-exchange" activeClassName="active" element={<ReturnExchnage />} />
          <Route path="/shipping" activeClassName="active" element={<Shipping />} />
          {/* customer service routes end */}
          
          <Route path="/padmakshya-club" activeClassName="active" element={<PadmakshyaClub />} />
          <Route path="/search-result/:slug" activeClassName="active" element={<SearchResult />} />
          <Route path="/:slug" activeClassName="active" element={<Pages />} />
          <Route path="/error_404" activeClassName="active" element={<PageNotFound />} />

        </Routes>
      </Router>

    </>
  );
}

export default App;
