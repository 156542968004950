import { BrowserView, MobileView } from "react-device-detect"
import React, { useEffect, useRef, useState, useCallback, useContext } from "react";
import Header from "../../Component/Header"
import Footer from "../../Component/Footer"
import ProductGallery from "../../Component/Element/product_gallery";
import ProductTabs from "../../Component/Element/product_tab";
import Accordion from 'react-bootstrap/Accordion';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Navigation, Scrollbar } from 'swiper/modules';
import { useParams, useNavigate } from "react-router-dom";
import { ApiService } from "../../Component/Service/apiservices";
import multiCurrency from "../../Component/Element/multiCurrency";
import Skeleton from "react-loading-skeleton";
import ProductBox from "../../Component/Element/product_box";
import SizeGuideModal from "../../Component/Modal/size_guide_modal";
import DataContext from "../../Component/Element/context";
import constant from "../../Component/Service/constant";
import Loader from "react-js-loader";
import { toast } from "react-toastify";
import { addToCart, addToCartSession } from "../../Component/Element/add_to_cart";
import DropHint from "../../Component/Modal/dropahint";
const ProductDetail = () => {
    const { slug } = useParams()
    const navigate = useNavigate();
    const didMountRef = useRef(true);
    const contextValues = useContext(DataContext)
    const [ProductsData, setProductsData] = useState({})
    const [sizeGuideBaseUrl, setSizeGuideBaseUrl] = useState('')
    const [adminData, setadminData] = useState({})
    const [dimensionData, setdimensionData] = useState({ length: '', width: '', height: "" })
    const [producttabs, setproducttabs] = useState([])
    const [galleryImage, setgalleryImage] = useState([])
    const [relatedProducts, setrelatedProducts] = useState([])
    const [loading, setloading] = useState({})
    const [variationDataa, setVariationData] = useState([]);
    const [arySelectedData, setArySelectedData] = useState([]);
    const [selvararray, setSelvararray] = useState([]);
    const [resCompleteTheLooksData, setResCompleteTheLooksData] = useState([]);
    const [termImageBaseUrl, setTermImageBaseUrl] = useState('');
    const sliderRef = useRef(null);
    useEffect(() => {
        if (didMountRef.current) {
            getProductdetail();
            getCompleteTheLooksData()
        }
        didMountRef.current = false;
    }, []);
    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);
    const dropHintModal = () => {
        contextValues.setToggledropHintModal(!contextValues.toggleDropHintModal)
    }

    const getProductdetail = () => {
        const dataString = {
            slug: slug
        }
        ApiService.postData('product-details', dataString).then((res) => {
            if (res.status == 'success') {
                setTermImageBaseUrl(res.termImageBaseUrl)
                setProductsData(res.rowProductData)
                setSizeGuideBaseUrl(res.sizeGuideBaseUrl)
                if (res.rowProductData?.product_type == 1) {
                    setdimensionData({
                        length: res.rowProductData?.default_variation_first?.pv_length,
                        width: res.rowProductData?.default_variation_first?.pv_width,
                        height: res.rowProductData?.default_variation_first?.pv_height
                    })
                } else {
                    setdimensionData({
                        length: res.rowProductData?.product_length,
                        width: res.rowProductData?.product_width,
                        height: res.rowProductData?.product_height
                    })
                }

                if (res.rowProductData.product_description !== null) {
                    const producttabs = [
                        {
                            tab_name: 'Description',
                            tab_description: res.rowProductData.product_description
                        },
                    ];

                    res.rowProductData.product_tabs && res.rowProductData.product_tabs?.map((value) => {
                        producttabs.push(value);
                    }); 
                    setproducttabs(producttabs)
                } else {
                    const producttabs =
                        res.rowProductData.product_tabs?.map((value) => {
                            producttabs.push(value);
                        }); 
                    setproducttabs(producttabs)
                }
                // setproducttabs(tabs)
                setgalleryImage(res?.arrayImages)
                setadminData(res.admin_data)
                setrelatedProducts(res.relatedProducts)
                setVariationData(res.variationData);
                setSelvararray(res.selvararray);
                res.variationData.map((parent) => {
                    if (parent.attributes && Number(parent.attributes.attribute_type) === 3) { //Text
                        parent.attr_terms.map((child) => {
                            if (res.selvararray.includes(child.terms_name)) {
                                arySelectedData.push({ attr: parent.attributes.attribute_name, terms: child.terms_name, terms_value: child.terms_name, terms_type: 3 });
                            }
                        });
                    } else if (parent.attributes && Number(parent.attributes.attribute_type) === 2) { //Image
                        parent.attr_terms.map((child) => {
                            if (res.selvararray.includes(child.terms_name)) {
                                arySelectedData.push({ attr: parent.attributes.attribute_name, terms: child.terms_name, terms_value: child.terms_image, terms_type: 2 });
                            }
                        });
                    } else if (parent.attributes && Number(parent.attributes.attribute_type) === 1) { //Color
                        parent.attr_terms.map((child) => {
                            if (res.selvararray.includes(child.terms_name)) {
                                arySelectedData.push({ attr: parent.attributes.attribute_name, terms: child.terms_name, terms_value: child.terms_value, terms_type: 1 });
                            }
                        });
                    }
                });
                setloading(false)
            } else {
                setloading(false)
            }
        }).catch((error) => {
            setloading(false)
        })
    }

    const getCompleteTheLooksData = () => {
        const dataString = {
            slug: slug
        }
        ApiService.postData('completeTheLooksData', dataString).then((res) => {
            if (res.status == 'success') {
                setResCompleteTheLooksData(res.resCompleteTheLooksData);
            }
        }).catch((error) => {
        })
    }

    const sizeguideModal = () => {
        contextValues.setToggleSizeguideModal(!contextValues.toggleSizeguideModal)
    }

    const variationSelect = (attrTerm, index, valueVariation) => {
        const updatedData = arySelectedData.map(item => {
            if (item.attr === valueVariation.attributes.attribute_name) {
                let terms_value;
                switch (Number(item.terms_type)) {
                    case 1: //Color
                        terms_value = attrTerm.terms_value;
                        break;
                    case 2: //Image
                        terms_value = attrTerm.terms_image;
                        break;
                    case 3: //Text
                        terms_value = attrTerm.terms_name;
                        break;
                    default:
                        terms_value = item.terms_value;
                }
                return { ...item, terms: attrTerm.terms_name, terms_value, terms_type: item.terms_type };
            }
            return item;
        });
        const dataString = {
            variation: updatedData,
            product_id: ProductsData.product_id,
        };
        ApiService.postData("variation-wise-price", dataString).then((res) => {
            setArySelectedData(updatedData);
            setgalleryImage(res.arrayImages);
            ProductsData.product_sku = res.data.pv_sku;
            ProductsData.product_selling_price = res.data.pv_sellingprice;
            ProductsData.product_price = res.data.pv_price;
            ProductsData.product_stock = res.data.pv_quantity;
            ProductsData.product_moq = res.data.pv_moq;
            ProductsData.product_discount = res.data.pv_discount;
            if (res.data) {
                if (res.data.pv_quantity == "") {
                    ProductsData.product_availability = "Available";
                    ProductsData.product_availability_status = false;
                } else if (Number(res.data.pv_quantity) == 0) {
                    ProductsData.product_availability = "Out of Stock";
                    ProductsData.product_availability_status = false;
                } else {
                    ProductsData.product_availability = "Available";
                    ProductsData.product_availability_status = true;
                }
            }

            setProductsData(ProductsData);
            setdimensionData({
                length: res.data?.pv_length,
                width: res.data?.pv_width,
                height: res.data?.pv_height
            })

        });
    };

    const addtofav = (addproduct) => {
        const productData = {
            product_id: Number(addproduct.product_id),
            product_name: addproduct.product_name,
            product_slug: addproduct.product_slug,
            product_image: galleryImage
                ? galleryImage[0]
                : constant.DEFAULT_IMAGE,
            product_type: Number(addproduct.product_type),
            product_price: parseFloat(addproduct.product_price).toFixed(2),
            product_selling_price: parseFloat(addproduct.product_selling_price).toFixed(2),
            product_discount: addproduct.product_discount,
            product_variation: selvararray,
            product_category_id: addproduct.product_category_id,
            selected_variation: arySelectedData,
            quantity: 1,
        };
        ApiService.postData("add-to-fav", productData).then((res) => {
            if (res.data.status == "success") {
                var element = document.getElementById("wishlisticon" + addproduct.product_id);
                element.classList.remove("ri-heart-fill", "ri-heart-line");
                element.classList.add(res.data.notification);
                contextValues.setFavCount(res.data.count)
                if (res.data.notification === "ri-heart-3-line") {
                    toast.success('Removed from wishlist');
                } else {
                    toast.success('Added to Wishlist');
                }
            }
        });
    };

    const addToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            const productData = {
                product_id: Number(addproduct.product_id),
                product_name: addproduct.product_name,
                product_slug: addproduct.product_slug,
                product_image: galleryImage ? galleryImage[0] : constant.DEFAULT_IMAGE,
                product_type: Number(addproduct.product_type),
                product_price: parseFloat(addproduct.product_price).toFixed(2),
                product_selling_price: parseFloat(addproduct.product_selling_price).toFixed(2),
                product_discount: addproduct.product_discount,
                product_variation: selvararray,
                product_category_id: addproduct.product_category_id,
                selected_variation: arySelectedData,
                product_availability_status: addproduct.product_availability_status,
                quantity: Number(1),
            };
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (contextValues.userToken) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    cartModal()
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    cartModal()
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }

    const addToCartCompleteLookProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (contextValues.userToken) {
                const updateStatus = await addToCartSession(addproduct.variation, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    cartModal()
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(addproduct.variation, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    cartModal()
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const variationModal = (productValue) => {
        contextValues.setProductData(productValue)
        setTimeout(() => {
            contextValues.setToggleVariationModal(!contextValues.toggleVariationModal)
        }, 100);
    }
    return (<>
        <Header innerHeader={'innerHeader'}></Header>

        <BrowserView>
            {loading ? <>
                <section className="single-product">
                    <div className="container-fluid px-0">
                        <div className="row g-0">
                            <div className="col-lg-6">
                                {[...Array(4)]?.map((_, index) => {
                                    return (<>
                                        <Skeleton key={index} height={'600px'} width={'100%'}></Skeleton>
                                    </>)
                                })}
                            </div>
                            <div className="col-lg-6">
                                <div className="product-details">
                                    <div className="product-details-header mb-10">
                                        <h1 className="mb-0 product-name"><Skeleton width={'500px'}></Skeleton></h1>
                                        <button type="button" className="wishlist"><Skeleton width={'30px'} height={'30px'}></Skeleton></button>
                                    </div>
                                    <div className="product-price">
                                        <ins className="new-price"><Skeleton width={'80px'}></Skeleton></ins>
                                        <del className="old-price"><Skeleton width={'80px'}></Skeleton></del>
                                    </div>

                                    <div className="variationAccord">
                                        <Accordion eventKey="0">
                                            <Accordion.Item eventKey="0">
                                                <h6 className="mb-0 tx-14"><Skeleton width={'80px'}></Skeleton></h6>
                                                <Accordion.Header>
                                                    <div className="productVarationMeta">
                                                        <Skeleton variant="rectangular" width={'20px'} height={'20px'}></Skeleton>
                                                        <Skeleton width={'80px'}></Skeleton>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Skeleton width={'80px'}></Skeleton>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <h6 className="mb-0 tx-14"><Skeleton width={'80px'}></Skeleton></h6>
                                                <Accordion.Header>
                                                    <div className="productVarationMeta">
                                                        <Skeleton variant="rectangular" width={'20px'} height={'20px'}></Skeleton>
                                                        <Skeleton width={'80px'}></Skeleton>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Skeleton width={'80px'}></Skeleton>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <h6 className="mb-0 tx-14"><Skeleton width={'50px'}></Skeleton></h6>
                                                <Accordion.Header><Skeleton width={'80px'}></Skeleton></Accordion.Header>
                                                <Accordion.Body>
                                                    <Skeleton width={'80px'}></Skeleton>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <Skeleton width={'500px'} className="btn  btn-full mt-15 mb-15"></Skeleton>
                                    <div className="product-footer mb-20">
                                        <a href="#" className="d-flex align-items-center gap-2">
                                            <p className="mb-0"><Skeleton width={'80px'}></Skeleton></p>
                                        </a>
                                        <a href="javascript:void(0)" className="d-flex align-items-center gap-2">
                                            <p className="mb-0"><Skeleton width={'80px'}></Skeleton></p>
                                        </a>
                                    </div>
                                    <div className="product-footer mt-20">
                                        <a href="javascript:void(0)" className="d-flex align-items-center gap-2" target="new">
                                            <p className="mb-0"><Skeleton width={'80px'}></Skeleton></p>
                                        </a>
                                        <a href="javascript:void(0)" className="d-flex align-items-center gap-2" target="new">
                                            <p className="mb-0"><Skeleton width={'80px'}></Skeleton></p>
                                        </a>
                                        <a href="javascript:void(0)" className="d-flex align-items-center gap-2" target="new">
                                            <p className="mb-0"><Skeleton width={'80px'}></Skeleton></p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </> :
                <>
                    <section className="single-product">
                        <div className="container-fluid px-0">
                            <div className="row g-0">
                                {galleryImage && galleryImage?.length > 0 && (
                                    <div className="col-lg-6">
                                        <ProductGallery galleryImage={galleryImage} loading={loading} productname={ProductsData?.product_name}></ProductGallery>
                                    </div>
                                )}
                                <div className="col-lg-6">
                                    <div className="product-details">
                                        <div className="product-details-header mb-10">
                                            {ProductsData?.product_name && <h1 className="mb-0 product-name">{ProductsData?.product_name}</h1>}
                                            {contextValues.userToken ? (
                                                ProductsData.ufp_id > 0 ? (
                                                    <button type="button" className="wishlist" onClick={(e) => addtofav(ProductsData)}><i className="ri-heart-fill" id={"wishlisticon" + ProductsData.product_id}></i></button>
                                                ) : (
                                                    <button type="button" className="wishlist" onClick={(e) => addtofav(ProductsData)}><i className="ri-heart-line" id={"wishlisticon" + ProductsData.product_id}></i></button>
                                                )
                                            ) : (
                                                <button type="button" className="wishlist" onClick={(e) => { navigate('/login') }}><i className="ri-heart-line"></i></button>
                                            )
                                            }
                                        </div>
                                        {ProductsData?.product_sku && <p>SKU : {ProductsData?.product_sku}</p>}
                                        <div className="product-price">
                                            {ProductsData?.product_selling_price && (<ins className="new-price">{multiCurrency(ProductsData?.product_selling_price)}</ins>)}
                                            {ProductsData?.product_price && Number(ProductsData?.product_price) > Number(ProductsData?.product_selling_price) && (<del className="old-price">{multiCurrency(ProductsData?.product_price)}</del>)}
                                        </div>
                                        {(dimensionData?.length || dimensionData?.width || dimensionData?.height) && (
                                            <div className="product-dimensions">
                                                <strong>Dimensions:</strong>
                                                {dimensionData?.length && (
                                                    <span> Length: {dimensionData.length} inches, </span>
                                                )}
                                                {dimensionData?.width && (
                                                    <span> Width: {dimensionData.width} inches, </span>
                                                )}
                                                {dimensionData?.height && (
                                                    <span> Height: {dimensionData.height} inches</span>
                                                )}
                                            </div>
                                        )}
                                        {ProductsData?.product_highlight && (
                                            <div className="hightlights">
                                                <ul className="p-0 pt-3 pb-3">
                                                    {ProductsData?.product_highlight?.split('##')?.map((item, index) => {
                                                        return (<>
                                                            <li key={index} style={{ listStyle: 'none', lineHeight: '30px' }}>{item}</li>
                                                        </>)
                                                    })}
                                                </ul>
                                            </div>
                                        )}
                                        {variationDataa && variationDataa.length > 0 && (
                                            <>
                                                <div className="variationAccord">
                                                    <Accordion eventKey="0">
                                                        {variationDataa.map((valueVariation, indexVariation) => {
                                                            if (valueVariation.attributes && valueVariation.attributes.attribute_type === 1) { // Color
                                                                const matchedRow = arySelectedData.find(
                                                                    item => item.attr === valueVariation.attributes.attribute_name
                                                                );
                                                                return (
                                                                    <>
                                                                        <Accordion.Item eventKey={indexVariation}>
                                                                            <h6 className="mb-0 tx-14">{valueVariation.attributes.attribute_name}</h6>
                                                                            <Accordion.Header>
                                                                                <div className="productVarationMeta">
                                                                                    <span className="color active" data-src="/img/default_image.png" href="javascript:void(0)" style={{ background: matchedRow && matchedRow.terms_value ? matchedRow.terms_value : '' }}></span>
                                                                                    {matchedRow ? matchedRow.terms : ""}
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body>
                                                                                {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                                                                    const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                                                                    const className = stringIncluded ? "color active" : "color";
                                                                                    return (
                                                                                        <div onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                                                                            className={'productVarationMeta'}
                                                                                            key={indexvalueVariationAttr}
                                                                                        >
                                                                                            <span class={className} data-src="/img/default_image.png" href="javascript:void(0)" style={{ background: valueVariationAttr.terms_value }}></span>
                                                                                            {valueVariationAttr.terms_name}</div>
                                                                                    );
                                                                                }
                                                                                )}
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    </>
                                                                );
                                                            } else if (valueVariation.attributes && valueVariation.attributes.attribute_type === 2) { //Image
                                                                const matchedRow = arySelectedData.find(
                                                                    item => item.attr === valueVariation.attributes.attribute_name
                                                                );
                                                                return (
                                                                    <>
                                                                        <Accordion.Item eventKey={indexVariation}>
                                                                            <h6 className="mb-0 tx-14">{valueVariation.attributes.attribute_name}</h6>
                                                                            <Accordion.Header>
                                                                                <div className="productVarationMeta">
                                                                                    <span className="swatch active" data-src={matchedRow.terms_value != null ? termImageBaseUrl + matchedRow.terms_value : constant.DEFAULT_IMAGE} href="javascript:void(0)" style={{ background: '#e6ca83' }}>
                                                                                        <img src={matchedRow.terms_value != null ? termImageBaseUrl + matchedRow.terms_value : constant.DEFAULT_IMAGE}></img>
                                                                                    </span>
                                                                                    {matchedRow ? matchedRow.terms : ""}
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body>
                                                                                {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                                                                    const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                                                                    const className = stringIncluded ? "swatch active" : "swatch";
                                                                                    return (
                                                                                        <div onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                                                                            className="productVarationMeta"
                                                                                            key={indexvalueVariationAttr}
                                                                                        >
                                                                                            <span className={className} data-src={valueVariationAttr.terms_image != null ? termImageBaseUrl + valueVariationAttr.terms_image : constant.DEFAULT_IMAGE} href="javascript:void(0)" style={{ background: '#e6ca83' }}>
                                                                                                <img src={matchedRow.terms_value != null ? termImageBaseUrl + matchedRow.terms_value : constant.DEFAULT_IMAGE}></img>
                                                                                            </span>
                                                                                            {valueVariationAttr.terms_name}
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                )}
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    </>
                                                                );
                                                            } else if (valueVariation.attributes && valueVariation.attributes.attribute_type === 3) { // Text
                                                                const matchedRow = arySelectedData.find(
                                                                    item => item.attr === valueVariation.attributes.attribute_name
                                                                );
                                                                return (
                                                                    <>
                                                                        <Accordion.Item eventKey={indexVariation}>
                                                                            <h6 className="mb-0 tx-14">{valueVariation.attributes.attribute_name}</h6>
                                                                            <Accordion.Header>{matchedRow ? matchedRow.terms : ""}</Accordion.Header>
                                                                            <Accordion.Body>
                                                                                {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                                                                    const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                                                                    const className = stringIncluded ? "text active" : "text";
                                                                                    return (
                                                                                        <div onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                                                                            className="productVarationMeta"
                                                                                            key={indexvalueVariationAttr}>
                                                                                            {valueVariationAttr.terms_name}
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                )}
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Accordion>
                                                </div>
                                            </>
                                        )}
                                        <button type="button" className="btn btn-primary btn-full mt-15 mb-15" onClick={(e) => addToCartProcess(ProductsData)}>{contextValues.spinnerCubLoader == ProductsData.product_id ? <Loader type="spinner-default" bgColor={'#fff'} color={'#fff'} size={30} /> : ProductsData.product_availability_status ? 'Add to Cart' : 'Make to Order'}</button>
                                        {!ProductsData.product_availability_status && (<p>If the design is not readily available it will be made to order, which will take us approximately 25-30 days</p>)}
                                        <div className="product-footer mb-20">
                                            <a href="javascript:void(0)" className="d-flex align-items-center gap-2" onClick={() => { dropHintModal() }}>
                                                <i className="ri-mail-send-line"></i> <p className="mb-0">Drop a Hint</p>
                                            </a>
                                            <a href="/size-guide" className="d-flex align-items-center gap-2">
                                                <p className="mb-0">Size Guide</p><i className="ri-pencil-ruler-line"></i>
                                            </a>
                                        </div>
                                        <ProductTabs producttabs={producttabs}></ProductTabs>
                                        <div className="product-footer mt-20">
                                            <a href={`mailto:${adminData?.admin_support_email}`} className="d-flex align-items-center gap-2" target="new">
                                                <i className="ri-mail-open-line" style={{ marginTop: '-5px' }}></i>
                                                <p className="mb-0">Email Us</p>
                                            </a>
                                            <a href={`tel:${adminData?.admin_support_mobile}`} className="d-flex align-items-center gap-2" target="new">
                                                <i className="ri-phone-line" style={{ marginTop: '-5px' }}></i>
                                                <p className="mb-0">Contact Us</p>
                                            </a>
                                            <a href={`https://wa.me/${adminData?.admin_whatsapp_no}`} className="d-flex align-items-center gap-2" target="new">
                                                <i className="ri-chat-1-line" style={{ marginTop: '-5px' }}></i>
                                                <p className="mb-0">Live Chat</p>
                                            </a>
                                        </div>
                                        {resCompleteTheLooksData && resCompleteTheLooksData.length > 0 && <>
                                            <div className="dividerLight mb-10 mt-10"></div>
                                            <h5 className="mb-20">Complete the look</h5>
                                            <div className="productHorizantionalSlider">
                                                <Swiper
                                                    ref={sliderRef}
                                                    spaceBetween={20}
                                                    modules={[Navigation, Scrollbar,]}
                                                    slidesPerView={1}
                                                >
                                                    {resCompleteTheLooksData.map((valueCompleteTheLooks, indexCompleteTheLooks) => {
                                                        return (
                                                            <SwiperSlide key={indexCompleteTheLooks}>
                                                                <div className="product productHorizantional">
                                                                    <a href={"/product/" + valueCompleteTheLooks.product_slug} className="product-media-shap">
                                                                        <figure className="product-media">
                                                                            {valueCompleteTheLooks.arrayImages && valueCompleteTheLooks.arrayImages.length >= 2 ?
                                                                                <>
                                                                                    <img src={valueCompleteTheLooks.arrayImages[0]} alt={valueCompleteTheLooks.product_name} />
                                                                                    <img src={valueCompleteTheLooks.arrayImages[1]} alt={valueCompleteTheLooks.product_name} />
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <img src={valueCompleteTheLooks.arrayImages[0]} alt={valueCompleteTheLooks.product_name} />
                                                                                    <img src={valueCompleteTheLooks.arrayImages[0]} alt={valueCompleteTheLooks.product_name} />
                                                                                </>
                                                                            }
                                                                        </figure>
                                                                    </a>
                                                                    <div className="product-details">
                                                                        <div className="product-details-header">
                                                                            <h2 className="product-name">
                                                                                <a href={"/product/" + valueCompleteTheLooks.product_slug}>{valueCompleteTheLooks.product_name}</a>
                                                                            </h2>
                                                                            <div className="product-price">
                                                                                <ins className="new-price">{multiCurrency(valueCompleteTheLooks.product_selling_price)}</ins>
                                                                                {Number(valueCompleteTheLooks.product_price) > Number(valueCompleteTheLooks.product_selling_price) && (<del className="old-price">{multiCurrency(valueCompleteTheLooks.product_price)}</del>)}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {valueCompleteTheLooks.product_type == 0 ?
                                                                                <button className="addcartbutton" onClick={(e) => addToCartCompleteLookProcess(valueCompleteTheLooks)}>Add to Cart <i className="ri-add-line"></i></button>
                                                                                :
                                                                                <button className="addcartbutton" onClick={(e) => variationModal(valueCompleteTheLooks)}>Add to Cart <i className="ri-add-line"></i></button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                    }
                                                    )}
                                                </Swiper>
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
            {loading ? <>
                <section className="section-gap-md">
                    <div className="container-fluid">
                        <div className="dividerDark"></div>
                        <div className="section-title mb-20 mt-20">
                            <h5 className="text-center"><Skeleton width={'400px'}></Skeleton></h5>
                        </div>
                        <div className="row">
                            {[...Array(4)]?.map((_, index) => {
                                return (<>
                                    <div className="col-lg-3" key={index}>
                                        <div className="product">
                                            <a href="javascript:void(0)" className="product-media-shap">
                                                <figure className="product-media">
                                                    <Skeleton width={'600px'} height={'600px'}></Skeleton>
                                                </figure>
                                            </a>
                                            <div className="product-details">
                                                <h2 className="product-name">
                                                    <a href="javascript:void(0)"><Skeleton width={'300px'}></Skeleton></a>
                                                </h2>
                                                <div className="product-price">
                                                    <ins className="new-price"><Skeleton width={'100px'}></Skeleton></ins>
                                                    <del className="old-price"><Skeleton width={'100px'}></Skeleton></del>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                        <div className="row text-center mt-20">
                            <div className="col-lg-12">
                                <button className="btn  wd-250" type="button"><Skeleton width={'200px'} height={'50px'}></Skeleton></button>
                            </div>
                        </div>
                    </div>
                </section>
            </> :
                <>
                    {relatedProducts && relatedProducts?.length > 0 ? <>
                        <section className="section-gap-md">
                            <div className="container">
                                <div className="dividerDark"></div>
                                <div className="section-title mb-20 mt-20">
                                    <h5>Discover our Collection</h5>
                                </div>
                                <div className="row">
                                    {relatedProducts && relatedProducts?.map((relatedproitem, index) => {
                                        return (<>
                                            <div className="col-lg-3">
                                                <ProductBox productValue={relatedproitem} classType="product" key={index}></ProductBox>
                                            </div>
                                        </>)
                                    })}
                                </div>
                                <div className="row text-center mt-20">
                                    <div className="col-lg-12">
                                        <button className="btn btn-primary-outline wd-250" type="button" onClick={(e) => navigate('/list/shop-all')}>Shop Now</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </> : ''}
                </>}
        </BrowserView>
        <MobileView>
            {loading ? <>
                <div className="single-product">
                    <Skeleton width={'100%'} height={'400px'}></Skeleton>

                    <div className="product-details">
                        <div className="product-details-header mb-10">
                            <h1 className="mb-0 product-name"> <Skeleton width={'285px'} height={'25px'}></Skeleton></h1>
                            <button type="button" className="wishlist" ><Skeleton width={'35px'} ></Skeleton></button>
                        </div>
                        <div className="product-price">
                            <ins className="new-price"><Skeleton width={'105px'} ></Skeleton></ins>
                            <del className="old-price"><Skeleton width={'105px'} ></Skeleton></del>
                        </div>
                        <Skeleton width={'335px'} className="btn  btn-full mt-15 mb-15" ></Skeleton>

                        <div className="product-footer mb-20">
                            <a href="javascript:void(0)" className="d-flex align-items-center gap-2">
                                <Skeleton width={'105px'} ></Skeleton>
                            </a>
                            <a href="javascript:void(0)" className="d-flex align-items-center gap-2">
                                <Skeleton width={'105px'} ></Skeleton>
                            </a>
                        </div>
                    </div>
                </div>
            </> : <>
                <div className="single-product">
                    <ProductGallery galleryImage={galleryImage} loading={loading} productname={ProductsData?.product_name}></ProductGallery>

                    <div className="product-details">
                        <div className="product-details-header mb-10">
                            {ProductsData?.product_name && <h1 className="mb-0 product-name">{ProductsData?.product_name}</h1>}
                            {contextValues.userToken ? (
                                ProductsData.ufp_id > 0 ? (
                                    <button type="button" className="wishlist" onClick={(e) => addtofav(ProductsData)}><i className="ri-heart-fill" id={"wishlisticon" + ProductsData.product_id}></i></button>
                                ) : (
                                    <button type="button" className="wishlist" onClick={(e) => addtofav(ProductsData)}><i className="ri-heart-line" id={"wishlisticon" + ProductsData.product_id}></i></button>
                                )
                            ) : (
                                <button type="button" className="wishlist" onClick={(e) => { navigate('/login') }}><i className="ri-heart-line"></i></button>
                            )
                            }
                        </div>
                        {ProductsData?.product_sku && <p>SKU : {ProductsData?.product_sku}</p>}
                        <div className="product-price">
                            {ProductsData?.product_selling_price && (<ins className="new-price">{multiCurrency(ProductsData?.product_selling_price)}</ins>)}
                            {ProductsData?.product_price && Number(ProductsData?.product_price) > Number(ProductsData?.product_selling_price) && (<del className="old-price">{multiCurrency(ProductsData?.product_price)}</del>)}
                        </div>
                        {(dimensionData?.length || dimensionData?.width || dimensionData?.height) && (
                            <div className="product-dimensions">
                                <strong>Dimensions:</strong>
                                {dimensionData?.length && (
                                    <span> Length: {dimensionData.length} inches, </span>
                                )}
                                {dimensionData?.width && (
                                    <span> Width: {dimensionData.width} inches, </span>
                                )}
                                {dimensionData?.height && (
                                    <span> Height: {dimensionData.height} inches</span>
                                )}
                            </div>
                        )}
                        {ProductsData?.product_highlight && (
                            <div className="hightlights">
                                <ul className="p-0 pt-3 pb-3">
                                    {ProductsData?.product_highlight?.split('##')?.map((item, index) => {
                                        return (<>
                                            <li key={index} style={{ listStyle: 'none', lineHeight: '30px' }}>{item}</li>
                                        </>)
                                    })}
                                </ul>
                            </div>
                        )}

                        {variationDataa && variationDataa.length > 0 && (
                            <>
                                <div className="variationAccord">
                                    <Accordion eventKey="0">
                                        {variationDataa.map((valueVariation, indexVariation) => {
                                            if (valueVariation.attributes && valueVariation.attributes.attribute_type === 1) { // Color
                                                const matchedRow = arySelectedData.find(
                                                    item => item.attr === valueVariation.attributes.attribute_name
                                                );
                                                return (
                                                    <>
                                                        <Accordion.Item eventKey={indexVariation}>
                                                            <h6 className="mb-0 tx-14">{valueVariation.attributes.attribute_name}</h6>
                                                            <Accordion.Header>
                                                                <div className="productVarationMeta">
                                                                    <span className="color active" data-src="/img/default_image.png" href="javascript:void(0)" style={{ background: matchedRow && matchedRow.terms_value ? matchedRow.terms_value : '' }}></span>
                                                                    {matchedRow ? matchedRow.terms : ""}
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                                                    const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                                                    const className = stringIncluded ? "color active" : "color";
                                                                    return (
                                                                        <div onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                                                            className={'productVarationMeta'}
                                                                            key={indexvalueVariationAttr}
                                                                        >
                                                                            <span class={className} data-src="/img/default_image.png" href="javascript:void(0)" style={{ background: valueVariationAttr.terms_value }}></span>
                                                                            {valueVariationAttr.terms_name}</div>
                                                                    );
                                                                }
                                                                )}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </>
                                                );
                                            } else if (valueVariation.attributes && valueVariation.attributes.attribute_type === 2) { //Image
                                                const matchedRow = arySelectedData.find(
                                                    item => item.attr === valueVariation.attributes.attribute_name
                                                );
                                                return (
                                                    <>
                                                        <Accordion.Item eventKey={indexVariation}>
                                                            <h6 className="mb-0 tx-14">{valueVariation.attributes.attribute_name}</h6>
                                                            <Accordion.Header>
                                                                <div className="productVarationMeta">
                                                                    <span className="swatch active" data-src={matchedRow.terms_value != null ? termImageBaseUrl + matchedRow.terms_value : constant.DEFAULT_IMAGE} href="javascript:void(0)" style={{ background: '#e6ca83' }}>
                                                                        <img src={matchedRow.terms_value != null ? termImageBaseUrl + matchedRow.terms_value : constant.DEFAULT_IMAGE}></img>
                                                                    </span>
                                                                    {matchedRow ? matchedRow.terms : ""}
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                                                    const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                                                    const className = stringIncluded ? "swatch active" : "swatch";
                                                                    return (
                                                                        <div onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                                                            className="productVarationMeta"
                                                                            key={indexvalueVariationAttr}
                                                                        >
                                                                            <span className={className} data-src={valueVariationAttr.terms_image != null ? termImageBaseUrl + valueVariationAttr.terms_image : constant.DEFAULT_IMAGE} href="javascript:void(0)" style={{ background: '#e6ca83' }}>
                                                                                <img src={matchedRow.terms_value != null ? termImageBaseUrl + matchedRow.terms_value : constant.DEFAULT_IMAGE}></img>
                                                                            </span>
                                                                            {valueVariationAttr.terms_name}
                                                                        </div>
                                                                    );
                                                                }
                                                                )}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </>
                                                );
                                            } else if (valueVariation.attributes && valueVariation.attributes.attribute_type === 3) { // Text
                                                const matchedRow = arySelectedData.find(
                                                    item => item.attr === valueVariation.attributes.attribute_name
                                                );
                                                return (
                                                    <>
                                                        <Accordion.Item eventKey={indexVariation}>
                                                            <h6 className="mb-0 tx-14">{valueVariation.attributes.attribute_name}</h6>
                                                            <Accordion.Header>{matchedRow ? matchedRow.terms : ""}</Accordion.Header>
                                                            <Accordion.Body>
                                                                {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                                                    const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                                                    const className = stringIncluded ? "text active" : "text";
                                                                    return (
                                                                        <div onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                                                            className="productVarationMeta"
                                                                            key={indexvalueVariationAttr}>
                                                                            {valueVariationAttr.terms_name}
                                                                        </div>
                                                                    );
                                                                }
                                                                )}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Accordion>
                                </div>
                            </>
                        )}
                        <button type="button" className="btn btn-primary btn-full mt-15 mb-15" onClick={(e) => addToCartProcess(ProductsData)}>{contextValues.spinnerCubLoader == ProductsData.product_id ? <Loader type="spinner-default" bgColor={'#fff'} color={'#fff'} size={30} /> : ProductsData.product_availability_status ? 'Add to Cart' : 'Make to Order'}</button>
                        {!ProductsData.product_availability_status && (<p>If the design is not readily available it will be made to order, which will take us approximately 25-30 days</p>)}
                        <div className="product-footer mb-20">
                            <a href="#" className="d-flex align-items-center gap-2">
                                <i className="ri-mail-send-line"></i> <p className="mb-0">Drop a Hint</p>
                            </a>
                            <a href="/size-guide" className="d-flex align-items-center gap-2">
                                <p className="mb-0">Size Guide</p><i className="ri-pencil-ruler-line"></i>
                            </a>
                        </div>

                        <ProductTabs producttabs={producttabs}></ProductTabs>
                        <div className="product-footer mt-20">
                            <a href={`mailto:${adminData?.admin_support_email}`} className="d-flex align-items-center gap-2" target="new">
                                <i className="ri-mail-open-line" style={{ marginTop: '-5px' }}></i>
                                <p className="mb-0">Email Us</p>
                            </a>
                            <a href={`tel:${adminData?.admin_support_mobile}`} className="d-flex align-items-center gap-2" target="new">
                                <i className="ri-phone-line" style={{ marginTop: '-5px' }}></i>
                                <p className="mb-0">Contact Us</p>
                            </a>
                            <a href={`https://wa.me/${adminData?.admin_whatsapp_no}`} className="d-flex align-items-center gap-2" target="new">
                                <i className="ri-chat-1-line" style={{ marginTop: '-5px' }}></i>
                                <p className="mb-0">Live Chat</p>
                            </a>
                        </div>
                        {resCompleteTheLooksData && resCompleteTheLooksData.length > 0 && <>
                            <div className="dividerLight mb-10 mt-10"></div>
                            <h5 className="mb-20">Complete the look</h5>
                            <div className="productHorizantionalSlider">
                                <Swiper
                                    ref={sliderRef}
                                    spaceBetween={20}
                                    modules={[Navigation, Scrollbar,]}
                                    slidesPerView={1}
                                >
                                    {resCompleteTheLooksData.map((valueCompleteTheLooks, indexCompleteTheLooks) => {
                                        return (
                                            <SwiperSlide key={indexCompleteTheLooks}>
                                                <div className="product productHorizantional">
                                                    <a href={"/product/" + valueCompleteTheLooks.product_slug} className="product-media-shap">
                                                        <figure className="product-media">
                                                            {valueCompleteTheLooks.arrayImages && valueCompleteTheLooks.arrayImages.length >= 2 ?
                                                                <>
                                                                    <img src={valueCompleteTheLooks.arrayImages[0]} alt={valueCompleteTheLooks.product_name} />
                                                                    <img src={valueCompleteTheLooks.arrayImages[1]} alt={valueCompleteTheLooks.product_name} />
                                                                </>
                                                                :
                                                                <>
                                                                    <img src={valueCompleteTheLooks.arrayImages[0]} alt={valueCompleteTheLooks.product_name} />
                                                                    <img src={valueCompleteTheLooks.arrayImages[0]} alt={valueCompleteTheLooks.product_name} />
                                                                </>
                                                            }
                                                        </figure>
                                                    </a>
                                                    <div className="product-details">
                                                        <div className="product-details-header">
                                                            <h2 className="product-name">
                                                                <a href={"/product/" + valueCompleteTheLooks.product_slug}>{valueCompleteTheLooks.product_name}</a>
                                                            </h2>
                                                            <div className="product-price">
                                                                <ins className="new-price">{multiCurrency(valueCompleteTheLooks.product_selling_price)}</ins>
                                                                {Number(valueCompleteTheLooks.product_price) > Number(valueCompleteTheLooks.product_selling_price) && (<del className="old-price">{multiCurrency(valueCompleteTheLooks.product_price)}</del>)}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {valueCompleteTheLooks.product_type == 0 ?
                                                                <button className="addcartbutton" onClick={(e) => addToCartCompleteLookProcess(valueCompleteTheLooks)}>Add to Cart <i className="ri-add-line"></i></button>
                                                                :
                                                                <button className="addcartbutton" onClick={(e) => variationModal(valueCompleteTheLooks)}>Add to Cart <i className="ri-add-line"></i></button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                    )}
                                </Swiper>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </>}

            {loading ? <>
                <section className="section-gap-sm">
                    <div className="container-fluid">
                        <div className="dividerDark"></div>
                        <div className="section-title mb-20 mt-20">
                            <h5 className="text-center"><Skeleton width={'300px'}></Skeleton></h5>
                        </div>
                        <div className="row">
                            {[...Array(4)]?.map((_, index) => {
                                return (<>
                                    <div className="col-lg-3" key={index}>
                                        <div className="product">
                                            <a href="javascript:void(0)" className="product-media-shap">
                                                <figure className="product-media">
                                                    <Skeleton width={'600px'} height={'600px'}></Skeleton>
                                                </figure>
                                            </a>
                                            <div className="product-details">
                                                <h2 className="product-name">
                                                    <a href="javascript:void(0)"><Skeleton width={'300px'}></Skeleton></a>
                                                </h2>
                                                <div className="product-price">
                                                    <ins className="new-price"><Skeleton width={'100px'}></Skeleton></ins>
                                                    <del className="old-price"><Skeleton width={'100px'}></Skeleton></del>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                        <div className="row text-center mt-20">
                            <div className="col-lg-12">
                                <button className="btn  wd-250" type="button"><Skeleton width={'200px'} height={'50px'}></Skeleton></button>
                            </div>
                        </div>
                    </div>
                </section>
            </> :
                <>
                    {relatedProducts && relatedProducts?.length > 0 ? <>
                        <section className="section-gap-sm">
                            <div className="container">
                                <div className="dividerDark"></div>
                                <div className="section-title mb-20 mt-20">
                                    <h5>Discover our Collection</h5>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <Swiper
                                            ref={sliderRef}
                                            spaceBetween={20}
                                            modules={[Navigation, Scrollbar,]}
                                            slidesPerView={2.2}
                                        >
                                            {relatedProducts.map((relatedproitem, index) => {
                                                return (

                                                    <SwiperSlide>
                                                        <ProductBox productValue={relatedproitem} classType="product" key={index}></ProductBox>
                                                    </SwiperSlide>

                                                )
                                            }
                                            )}
                                        </Swiper>
                                    </div>

                                </div>
                                <div className="row text-center mt-20">
                                    <div className="col-lg-12">
                                        <button className="btn btn-primary-outline wd-250" type="button" onClick={(e) => navigate('/list/shop-all')}>Shop Now</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </> : ''}
                </>}
        </MobileView>

        {contextValues?.toggleSizeguideModal && <SizeGuideModal sizeguide={ProductsData.sizeguide} sizeGuideBaseUrl={sizeGuideBaseUrl}></SizeGuideModal>}
        <Footer></Footer>

        {contextValues?.toggleDropHintModal && <DropHint ProductsData={ProductsData} ></DropHint>}
    </>)
}

export default ProductDetail