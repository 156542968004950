import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../Service/apiservices';
import DataContext from './context';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { validEmail } from "../Element/Regex";
import Accordion from 'react-bootstrap/Accordion';

const GiftCardForm = () => {
    const [selectedAmount, setSelectedAmount] = useState(1100);
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [loader, setloader] = useState('')
    const userSession = localStorage.getItem('USER_TOKEN');
    const contextValues = useContext(DataContext)
    const { Razorpay } = useRazorpay();
    const [continuePaySpinnerLoading, setContinuePaySpinnerLoading] = useState(false);
    const [Inputfields, setInputfields] = useState({
        reciepient_name: '',
        reciepient_mail: '',
        reciepient_mobile: '',

    });
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const Navigate = useNavigate();

    const handleSelectAmount = (amount) => {
        setSelectedAmount(amount);
    };
    const onInputChange = (e) => {
        setErrorMessage('')
        const { name, value } = e.target;
        setInputfields(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const ConfirmDetailModal = () => {
        setInputfields({
            reciepient_name: '',
            reciepient_mail: '',
            user_name: '',
            user_mail: '',
        });
        setConfirmModal(!ConfirmModal)
    }

    const handleBuyNowClick = () => {
        if (!userSession) {
            // Redirect to login with the return URL
            window.location.href = `/login?redirect=/gifting`;
        } else {
            if (Inputfields.reciepient_name == '') {
                setErrorMessage('Please enter the Reciepient Name')
                return
            }
            if (Inputfields.reciepient_mail == '') {
                setErrorMessage('Please enter the Reciepient Email')
                return
            }
            if (!validEmail.test(Inputfields.reciepient_mail)) {
                setErrorMessage('Invalid Email Format')
                return false;
            }
            if (Inputfields.reciepient_mobile == '') {
                setErrorMessage('Please enter the Reciepient Mobile')
                return
            }

            const dataString = {
                amount: selectedAmount,
                reciepient_name: Inputfields?.reciepient_name,
                reciepient_mail: Inputfields?.reciepient_mail,
                reciepient_mobile: Inputfields?.reciepient_mobile,
            }
            setContinuePaySpinnerLoading(true);
            ApiService.postData("creategiftcardTempOrder", dataString).then((res) => {
                if (res.status === "success") {
                    handlePayment(res.temp_trans_order_id, res.temp_razorpay_order_id)
                    setTimeout(() => {
                        setContinuePaySpinnerLoading(false);
                    }, 4000);
                } else {
                    toast.error(res.message)
                    setContinuePaySpinnerLoading(false);
                }
            });
        }
    };

    const handlePayment = async (temp_trans_order_id, temp_razorpay_order_id) => {
        const options = {
            key: "rzp_live_L5ABuNyEjss7o7",
            amount: 0,
            currency: "INR",
            name: "PADMAKSHYA",
            description: "PADMAKSHYA",
            image: contextValues.settingImageBaseUrl + contextValues.settingData.logo,
            order_id: temp_razorpay_order_id,
            handler: function (response) {
                RazorPayProcess(response, temp_trans_order_id);
            },
            prefill: {
                name: contextValues?.rowUserData?.user_fname + ' ' + contextValues.userSelectedAddress.ua_lname,
                email: contextValues?.rowUserData?.user_email,
                contact: contextValues?.rowUserData?.user_mobile,
            },
            notes: {
                address: "PADMAKSHYA",
            },
            theme: {
                color: "#ce8992",
            }
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });

        rzp1.open();
    };

    const RazorPayProcess = (response, orderId) => {
        const paymentData = {
            paymentId: response.razorpay_payment_id,
            orderId: orderId,
        };
        // console.log(paymentData);
        // return false;

        setContinuePaySpinnerLoading(true)
        ApiService.postData("giftcardpaymentProcessing", paymentData).then((result) => {
            if (result.status === "success") {
                setContinuePaySpinnerLoading(false);
                Navigate('/giftcardthankyou/' + result.order_number)
            } else {
                setContinuePaySpinnerLoading(false);
            }
        });
    };

    return (
        <>
             <h6 >A gift that can never go wrong. Let them choose their perfect gift with our e-gift card, <br></br>simply choose an amount, checkout and send their way to make their day!
             </h6>
            <h5>Gift Amount</h5>
            <ul className='gift-amount-list'>
                {[11000, 21000, 51000].map((amount) => (
                    <li
                        key={amount}
                        onClick={() => handleSelectAmount(amount)}
                        className={selectedAmount === amount ? 'active' : ''}
                    >
                        ₹{amount}
                    </li>
                ))}
            </ul>
            <button
                className="btn btn-lg btn-primary mb-20 mt-20"
                style={{ width: "47%" }}
                // onClick={handleBuyNowClick}
                onClick={() => { ConfirmDetailModal() }}
            >
                {continuePaySpinnerLoading ? <img src="/img/loder01.gif" width="70px" height="13px" /> : 'Buy Now'}
            </button>
            <div className='mt-3'>
                <h6>Terms & Conditions to use Gift Card</h6>
                <ul style={{ listStyle: 'none', padding: '0px', lineHeight: '25px' }}>
                    <li><a href='javascript:void(0)'> <i class="ri-check-double-line"></i> This card can only be redeemed on the Padmakshya website.</a></li>
                    <li><a href='javascript:void(0)'> <i class="ri-check-double-line"></i> This Gift Card is valid for one year from the day of purchase.</a></li>
                    <li><a href='javascript:void(0)'> <i class="ri-check-double-line"></i> This Gift Card is non-refundable and cannot be exchanged for cash.</a></li>
                    <li><a href='javascript:void(0)'> <i class="ri-check-double-line"></i> This Gift Card can only be redeemed in full. Partial redemption will not be allowed.</a></li>
                </ul>
            </div>

            <Modal show={ConfirmModal} onHide={(e) => ConfirmDetailModal()} className="giftpop">
                <button type="button" className="giftpop-Close" onClick={() => { ConfirmDetailModal() }}><i className="ri-close-line ri-xl"></i></button>
                <div className="giftpop-content">
                    <div>
                        <h4>Gift Card Details</h4>
                        <p>Please enter gift card recipient details</p>
                        {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                        {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                        <div className="form-group mb-15">
                            <input type="text" placeholder="Name" name="reciepient_name" value={Inputfields.reciepient_name} className='userdrophintRequired' onChange={(e) => { onInputChange(e) }} />
                        </div>
                        <div className="form-group mb-15">
                            <input type="text" placeholder="Email address" name="reciepient_mail" value={Inputfields.reciepient_mail} className='userdrophintRequired' onChange={(e) => { onInputChange(e) }} />
                        </div>
                        <div className="form-group mb-15">
                            <input type="text" placeholder="Mobile number" name="reciepient_mobile" value={Inputfields.reciepient_mobile} className='userdrophintRequired' onChange={(e) => { onInputChange(e) }} />
                        </div>
                        <button class="btn btn-lg btn-primary btn-full" onClick={() => { handleBuyNowClick() }} disabled={continuePaySpinnerLoading}>{continuePaySpinnerLoading ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : 'Pay Now'}</button>
                    </div>
                </div>
            </Modal>
        </>

    );
};

export default GiftCardForm;
