import Footer from "../../../Component/Footer"
import Header from "../../../Component/Header"
import Accordion from 'react-bootstrap/Accordion';
import { BrowserView, MobileView , isMobile } from "react-device-detect"
import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import constant from "../../../Component/Service/constant";
import { ApiService } from "../../../Component/Service/apiservices";
import DataContext from "../../../Component/Element/context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';


const FAQ = () => {
    const contextValues = useContext(DataContext)
    const [faqcatName, setfaqCatName] = useState('')
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    const [faqData, setfaqData] = useState([])
    const [faqcatwiseData, setfaqcatwiseData] = useState([])
    const [faqimgUrl, setfaqimgUrl] = useState('')
    const [addActive, setaddActive] = useState('')
    const [isLoading, setisLoading] = useState(false)
    useEffect(() => {
        if (didMountRef.current) {
            getfaqData()

        }
        didMountRef.current = false;
    }, []);
    const getfaqData = () => {
        setisLoading(true)
        ApiService.fetchData('categoryFaqs').then((res) => {
            if (res.status == 'success') {
                setfaqData(res.faqCatData)
                setfaqimgUrl(res.category_image_path)
                setfaqCatName(res?.faqCatData?.[0].fc_name)
                setisLoading(false)
                setfaqcatwiseData(res.faqCatData[0].faqs)
                setaddActive(res.faqCatData[0].fc_id)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }


    const getcatwisefaq = (catId) => {
        setaddActive(catId)
        const filteredFaqs = faqData.filter(faq => faq.fc_id === catId);
        setfaqCatName(filteredFaqs[0].fc_name)
        setfaqcatwiseData(filteredFaqs[0].faqs);

    }
    const slidermobRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!slidermobRef.current) return;
        slidermobRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!slidermobRef.current) return;
        slidermobRef.current.swiper.slideNext();
    }, []);

    return (<>
        <Header innerHeader={'innerHeader'}></Header>
        {isLoading ? <> <section className="section-gap-medium tx-center">
            <div className="section-title tx-center">
                <h1><Skeleton width={'200px'}></Skeleton></h1>
                <p className="mb-0"><Skeleton width={'300px'}></Skeleton></p>
            </div>
        </section>

            <section className="section-gap-medium bg-light01" >
                <Swiper

                    modules={[Navigation, A11y]}
                    // slidesPerView={5}
                    spaceBetween={20}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                    }}

                >
                    {[...Array(5)].map((item, index) => {
                        return (<>
                            <SwiperSlide key={index}>
                                <div className="faqcatbodc">
                                    <div className="faqcatbodcContent">
                                        <Skeleton></Skeleton>
                                        <p><Skeleton></Skeleton></p>
                                    </div>
                                </div>
                            </SwiperSlide>


                        </>)
                    })}
                </Swiper>


            </section>

            <section className="section-gap-medium">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="section-title mb-20 text-center">
                                <h3><Skeleton width={'300px'}></Skeleton></h3>
                            </div>
                            <div className="faqaccordation">

                                <Accordion defaultActiveKey="0">
                                    {[...Array(6)].map((_, index) => (
                                        <Accordion.Item eventKey={index.toString()} key={index}>
                                            <Accordion.Header>
                                                <Skeleton width={400} />
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Skeleton count={3} />
                                                <Skeleton width="80%" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="section-gap-small tx-center bg-light01">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <Skeleton width={'100px'} height={'100px'}></Skeleton>
                            <p><Skeleton width={'300px'}></Skeleton></p>
                            <p><Skeleton width={'300px'}></Skeleton></p>
                            <p><a className="btn btn-underline-primary p-0" href="#"><Skeleton width={'200px'}></Skeleton></a></p>
                            <p><a className="btn btn-underline-primary p-0" href="#"><Skeleton width={'200px'}></Skeleton> </a></p>
                            <p className="mb-0"><a className="btn btn-underline-primary p-0" href="#"><Skeleton width={'200px'}></Skeleton></a></p>
                        </div>
                    </div>
                </div>
            </section></> : <>

            <section className="section-gap-medium tx-center">
                <div className="section-title tx-center">
                    <h1>FAQ</h1>
                    <p className="mb-0">Find the questions most often grouped together in the themes below.</p>
                </div>
            </section>
            {faqData && faqData.length > 0 ? <>
                <section className="section-gap-medium bg-light01" >
                    <div className="container">
                        <div className="faqSlider">
                            <Swiper

                                modules={[Navigation, A11y]}
                                ref={slidermobRef}
                                spaceBetween={20}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                    1024: {
                                        slidesPerView: 5,
                                        spaceBetween: 20,
                                    },
                                }}
                            >
                                {faqData.map((item, index) => {
                                    return (<>
                                        <SwiperSlide key={index}>
                                            <div className={`faqcatbodc ${addActive == item.fc_id ? 'active' : ''}`} onClick={() => { getcatwisefaq(item.fc_id) }}>
                                                <div className="faqcatbodcContent">
                                                    <img src={item.fc_image ? faqimgUrl + item.fc_image : constant.DEFAULT_IMAGE}></img>
                                                    <p>{item.fc_name}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>


                                    </>)
                                })}
                            </Swiper>
                            {/* {isMobile && faqData.length>1 ?<div className='swiper-arrow'>
                                <div className="prev-arrow" onClick={handlePrev}><img src='/img/leftarrow.svg' alt="Previous" /></div>
                                <div className="next-arrow" onClick={handleNext}><img src='/img/rightarrow.svg' alt="Next" /></div>
                            </div>:faqData.length>5 ?<div className='swiper-arrow'>
                                <div className="prev-arrow" onClick={handlePrev}><img src='/img/leftarrow.svg' alt="Previous" /></div>
                                <div className="next-arrow" onClick={handleNext}><img src='/img/rightarrow.svg' alt="Next" /></div>
                            </div>:''} */}
                            
                        </div>

                    </div>
                </section>

                <section className="section-gap-medium">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="section-title mb-20">
                                    <h3>{faqcatName}</h3>
                                </div>
                                {faqcatwiseData && faqcatwiseData.length > 0 ? <>
                                    <div className="faqaccordation">
                                        <Accordion defaultActiveKey={0}>
                                            {faqcatwiseData?.map((items, index) => {
                                                return (<>
                                                    <Accordion.Item eventKey={index}>
                                                        <Accordion.Header>{items.faq_title}</Accordion.Header>
                                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: items.faq_description }}>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </>)
                                            })}
                                        </Accordion>
                                    </div>

                                </> : <div className="faqaccordation">
                                    <p >No FAQ Found for this category</p>
                                </div>}

                            </div>
                        </div>
                    </div>
                </section>
            </> : null}
        </>}

        <section className="section-gap-small tx-center bg-light01">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <img src="/img/contactus.png" className="mb-20"></img>
                        <p>Contact us</p>
                        <p>We remain at your disposal to answer any questions.</p>
                        <p><a className="btn btn-underline-primary p-0" href={`https://wa.me/${contextValues.settingData.admin_whatsapp_no}`}>Chat with us</a></p>
                        <p><a className="btn btn-underline-primary p-0" href={`tel:${contextValues.settingData.admin_support_mobile}`}>Call us </a></p>
                        <p className="mb-0"><a className="btn btn-underline-primary p-0" href={`mailto:${contextValues.settingData.admin_support_email}`}>E-mail us</a></p>
                    </div>
                </div>
            </div>
        </section>


        <Footer></Footer>
    </>)
}

export default FAQ