
import Modal from 'react-bootstrap/Modal';
import React, { useContext, useState, useEffect, useRef } from 'react';
import DataContext from '../Element/context';
import FilterSidebar from '../../Container/ProductList/filter_sidebar';
import { ApiService } from '../Service/apiservices';
import { useLocation } from 'react-router-dom';


const FilterModal = ({ setSingleFilter, getProductListing, clearFilters, clearSingleFilter, setSelectedSort, setPriceRangeValue }) => {
   
    const contextValues = useContext(DataContext)
  
    const didMountRef = useRef(true)
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [filters, setFilters] = useState([]);
    const [appliedfilters, setappliedfilters] = useState([]);
    const [appliedprice, setappliedprice] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [priceRange, setPriceRange] = useState(null);

    useEffect(() => {
        if (didMountRef.current) {
            getCategories()
        }
        didMountRef.current = false;
    })
    const getCategories = () => {
        ApiService.fetchData(`all-categories-with-products`).then((res) => {
            if (res.status == "success") {
                setCategories(res.resCategory)
                setFilters(res.attributesFilter)
                setSpinnerLoading(false)
            } else {
                setSpinnerLoading(false)
            }
        }).catch(() => { setSpinnerLoading(false) })
    }

    const setAttr = (id, text) => {
      
        if (!selectedFilters.includes(id)) {
            setSelectedFilters([...selectedFilters, id]);
        } else {
            setSelectedFilters(selectedFilters.filter(filterId => filterId !== id));
        }
        if (!appliedfilters.some(filter => filter.id === id)) {
            setappliedfilters([...appliedfilters, { 'id': id, 'text': text }]);
            setSingleFilter(id);
        } else {
            setappliedfilters(appliedfilters.filter(filter => filter.id !== id));
            clearSingleFilter(id);
        }
       
    }

    const clearFilter = () => {
        setappliedfilters([]);
        setSelectedFilters([]);
        setappliedprice([]);
        setPriceRange(null);
        setPriceRangeValue(null)
        clearFilters();
        setTimeout(()=>{
            getProductListing(1, 'clearfilter')
            filterModal()
        }, 500)  
    }

    const setPrice = (field , calltype) => {
           
        if (field.target.checked) {
            setPriceRange(field.target.value);
            setPriceRangeValue(field.target.value)
            setappliedprice([{ 'id': field.target.value, 'text': field.target.value }]);
           
        } else {
            setPriceRange(null);
            setPriceRangeValue(null)
            setappliedprice([]);
        }
       
    }
    const filterModal = () => {
        contextValues.settoggleFilterModal(!contextValues.toggleFilterModal)
    }

    const onApply=()=>{
        getProductListing(1)
        filterModal()
    }

    const location = useLocation()
    const locationPathname = location.pathname
    const extractedString = locationPathname.replace('/list/category/', '');
    return (<>
        <Modal show={contextValues.toggleFilterModal} onHide={(e) => filterModal()} className='left '>

            <div >
                <div >
                    <h5 className='mb-0 tx-uppercase'>Filter Modal </h5>
                    <button type="button" className="cart-close" onClick={() => { filterModal() }}><i className="ri-close-line ri-xl"></i></button>
                    <div className="sidebarWidget">
                        <div className="sidebarWidgetHeader">
                            <h5>Price</h5>
                        </div>
                        <div className="sidebarWidgetBody">
                            <div class="el-checkbox">
                                <input type="checkbox" checked={priceRange === 'under-15000'} name="check" value={"under-15000"} onChange={(e) => {setPrice(e, 'mobile')}} id="1_1" />
                                <label class="el-checkbox-style" for="1_1"></label>
                                <span class="margin-r">Under 15000</span>
                            </div>
                            <div class="el-checkbox">

                                <input type="checkbox" checked={priceRange === 'under-40000'} name="check" value={"under-40000"} onChange={(e) => {setPrice(e, 'mobile')}} id="1_2" />
                                <label class="el-checkbox-style" for="1_2"></label>
                                <span class="margin-r">Under 40000</span>
                            </div>
                            <div class="el-checkbox">

                                <input type="checkbox" checked={priceRange === 'under-70000'} name="check" value={"under-70000"} onChange={(e) => {setPrice(e, 'mobile')}} id="1_3" />
                                <label class="el-checkbox-style" for="1_3"></label>
                                <span class="margin-r">Under 70000</span>
                            </div>
                            <div class="el-checkbox">

                                <input type="checkbox" checked={priceRange === 'above-70000'} name="check" value={"above-70000"} onChange={(e) => {setPrice(e, 'mobile')}} id="1_4" />
                                <label class="el-checkbox-style" for="1_4"></label>
                                <span class="margin-r">Above 70000</span>
                            </div>
                        </div>
                    </div>
                    {categories.length ?
                        <div className="sidebarWidget">
                            <div className="sidebarWidgetHeader">
                                <h5>Categories</h5>
                            </div>
                            <div className="sidebarWidgetBody">
                                <ul className="slist">
                                    {categories.map((category, index) => {
                                        if (category.product_count > 0) {
                                            return <li key={index} className={extractedString == category.cat_slug ? 'active' : ''}><a href={category.cat_slug == 'shop-all' ? '/list/shop-all' : "/list/category/" + category.cat_slug}>{category.cat_name} ({category.product_count})</a> </li>
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                        : ""
                    }
                    {filters.length ? <>
                        {filters.map((filter, index) => {
                            return <div className="sidebarWidget">
                                <div className="sidebarWidgetHeader">
                                    <h5>{filter.attribute_name}</h5>
                                </div>
                                <div className="sidebarWidgetBody">
                                    {filter.attribute_type === 1 ?
                                        <ul className="mslist">
                                            {filter.attributeterms.map((terms, index) => {
                                                return <li className={(selectedFilters.indexOf(terms.terms_id) > -1) ? "active" : ""} onClick={() => { setAttr(terms.terms_id, terms.terms_name) }}>
                                                    <span className="colorb" style={{ background: terms.terms_value }}></span>
                                                    <span>{terms.terms_name}</span>
                                                </li>
                                            })}
                                        </ul>
                                        : filter.attribute_type === 3 ? <ul className="sizelist">
                                            {filter.attributeterms.map((terms, index) => {
                                                return <li className={(selectedFilters.indexOf(terms.terms_id) > -1) ? "active" : ""} onClick={() => { setAttr(terms.terms_id, terms.terms_name) }}>{terms.terms_name}</li>
                                            })}
                                        </ul> : ""}
                                </div>
                            </div>

                        })}

                    </> : ""}
                    <button onClick={()=>{onApply()}}>Apply</button>
                    <button onClick={()=>{clearFilter()}}>Clear Filter</button>
                </div>
            </div>
        </Modal>
    </>)
}


export default FilterModal